import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App);

import axios from 'axios'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const options = {
    confirmButtonColor: '#efce03',
    cancelButtonColor: '#1d1d29',
};

app.use(VueSweetalert2, options);

import ReadonlyDirective from './diretivas/readonly';

app.directive('readonly', ReadonlyDirective);

import VueClipboard from 'vue-clipboard2';
app.use(VueClipboard);


import {DataTable} from "simple-datatables"
import 'simple-datatables/src/css/style.css';

app.config.globalProperties.$DataTable = DataTable;

app.use(store);
app.use(router);

// app.config.globalProperties.baseStorage = 'http://127.0.0.1:8000/storage/';
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/v1';
// app.config.globalProperties.$base = 'http://127.0.0.1:8080/';
// app.config.globalProperties.$api = 'http://127.0.0.1:8000/';

app.config.globalProperties.baseStorage = 'https://api.wteamdev.com/storage/';
axios.defaults.baseURL = 'https://api.wteamdev.com/api/v1';
app.config.globalProperties.$base = 'https://comecpi.com/';
app.config.globalProperties.$api = 'https://api.wteamdev.com/api/v1/';

axios.defaults.headers.common['Accept'] = 'application/json';
app.config.globalProperties.$http = axios;

import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';
app.use(CkeditorPlugin);

app.mount('#app');