<template>
  <div>
    <Sidebar />
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Leitor de QrCode</h1>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders d-flex justify-content-center">
            <div class="center stream">
              <div class="row">
                <div class="col-12">
                  <qr-stream @decode="onDecode" class="mb" style="height: 400px;">
                    <div style="color: red;" class="frame"></div>
                  </qr-stream>
                </div>

              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie';
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";
import { ref, onMounted, getCurrentInstance } from 'vue';
import { QrStream } from 'vue3-qr-reader';

export default {
  components: {
    NavBar,
    Sidebar,
    QrStream,
  },
  setup() {
    const data = ref('');
    const token = ref('');
    const api = getCurrentInstance().appContext.config.globalProperties.$api;
    const hasDecoded = ref(false); // Estado para verificar se já decodificou

    const onDecode = (decodedData) => {
      if (!decodedData || hasDecoded.value) return; // Verifica se já leu um QR code

      hasDecoded.value = true; // Marca que já decodificou

      alert("Código identificado: " + decodedData);

      fetch(`${api}reports`, {
        method: 'POST',
        headers: {
          "Authorization": "Bearer " + token.value,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ code: decodedData }) // Pass the necessary data
      })
      .then(response => response.json())
      .then(response => {
        data.value = response.info.full_name;
        alert('O registro de ' + data.value + ' foi realizado!');
      })
      .catch(() => {
        alert('Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
      });

      // Reinicia o estado após um tempo ou conforme sua lógica
      setTimeout(() => {
        hasDecoded.value = false; // Permite nova leitura após 5 segundos (ajuste conforme necessário)
      }, 5000);
    }

    onMounted(() => {
      if (Cookie.get('_token')) {
        token.value = Cookie.get('_token');
      }
    });

    return {
      data,
      onDecode,
      token
    };
  }
};
</script>
