<template>
  <div :class="{ sidebar: true, close: sidebarClosed }">
      <div class="menu-top mobile-none">
        <router-link to="/" class="logo">
            <!-- <img class="logo-icon" src="@/assets/images/icon.svg" alt="Logo">
            <img class="logo-name" src="@/assets/images/wordmark.svg" alt="Logo"> -->
        </router-link>

        <div class="close-sidebar">
          <i class="bx bx-menu" @click="toggleSidebar"></i>
        </div>
      </div>
      <ul class="side-menu">
        <li :class="{ active: $route.path === '/' }">
          <router-link to="/">
            <i class="bx bxs-dashboard"></i>
            Inicio
          </router-link>
        </li>
        <li  :class="{ active: $route.path.startsWith('/participantes') }">
          <router-link to="/participantes">
            <i class="bx bxs-user-voice"></i>
            Participantes
          </router-link>
        </li>
        <li  :class="{ active: $route.path.startsWith('/relacao') }">
          <router-link to="/relacao">
            <i class="bx bx-note"></i>
            Relação
          </router-link>
        </li>
        <li  :class="{ active: $route.path.startsWith('/leitor') }">
          <router-link to="/leitor">
            <i class="bx bx-camera"></i>
            Leitor
          </router-link>
        </li>
        <li :class="{ active: $route.path === '/config' }">
          <router-link to="/config">
            <i class="bx bx-cog"></i>
            Minha Conta
          </router-link>
        </li>
        <li data-bs-title="Sair">
          <label href="" class="item logout" @click="logout">
            <i class="bx bx-log-out-circle"></i>
            Sair
          </label>
        </li>
      </ul>
    </div>
</template>

<script>
import Cookie from 'js-cookie'
export default {
  name: 'SideBar',
  data() {
    return {
      role: 0,
      sidebarClosed: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.sidebarClosed = !this.sidebarClosed;
    },
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {
          if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
              if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
              }
          }
          } else {
          textMsg = `${msg.message}`;
          }
      } else {
          textMsg = `${msg}`;
      }

      this.$swal({
          text: textMsg,
          icon: type,
          confirmButtonText: 'Confirmar',
          html: textMsg
      });
    },
    logout(){
        this.showAlert('success', 'Você saiu da conta com sucesso!');
        Cookie.remove('_token');

        setTimeout(() => {
            this.$router.push('/login');
        }, 1000);
    }
  },
  created() {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 768) {
        this.sidebarClosed = true;
      } else {
        this.sidebarClosed = false;
      }
    });

    this.role = this.$store.state.role;
  },
  computed: {

  },
};
</script>
